<template>
  <default-list
    :items="items"
    class="mb-n2"
  />
</template>

<script>
  import { mapGetters } from 'vuex';
  export default {
    name: 'DefaultAccountSettings',

    components: {
      DefaultList: () => import(
        /* webpackChunkName: "default-list" */
        '../List'
      ),
    },

    data: () => ({}),

    computed: {
      ...mapGetters({
        me: 'auth/getMe',
      }),
      items() {
        return [{
          title: this.me && this.me.full_name,
          icon: 'mdi-account',
          items: [
            {
              title: this.$t('my-profile'),
              icon: 'mdi-account-check',
              to: '/profile',
            },
            {
              title: this.$t('logout'),
              icon: 'mdi-account-off',
              to: '/logout',
            },
          ],
        }];
      },
    },
  }
</script>
